/*------------------------------ Css General----------------------- */
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Gibson-Bold";
  src: url("../assets/fonts/Gibson-Bold.otf");
}

@font-face {
  font-family: "Gibson-BoldItalic";
  src: url("../assets/fonts/Gibson-BoldItalic.otf");
}

@font-face {
  font-family: "Gibson-Book";
  src: url("../assets/fonts/Gibson-Book.otf");
}

@font-face {
  font-family: "Gibson-BookItalic";
  src: url("../assets/fonts/Gibson-BookItalic.otf");
}

@font-face {
  font-family: "Gibson-Heavy";
  src: url("../assets/fonts/Gibson-Heavy.otf");
}

@font-face {
  font-family: "Gibson-HeavyItalic";
  src: url("../assets/fonts/Gibson-Heavy.otf");
}

@font-face {
  font-family: "Gibson-Italic";
  src: url("../assets/fonts/Gibson-Italic.otf");
}

@font-face {
  font-family: "Gibson-Light";
  src: url("../assets/fonts/Gibson-Light.otf");
}

@font-face {
  font-family: "Gibson-LightItalic";
  src: url("../assets/fonts/Gibson-LightItalic.otf");
}

@font-face {
  font-family: "Gibson-Medium";
  src: url("../assets/fonts/Gibson-Medium.otf");
}

@font-face {
  font-family: "Gibson-MediumItalic";
  src: url("../assets/fonts/Gibson-MediumItalic.otf");
}

@font-face {
  font-family: "Gibson-Regular";
  src: url("../assets/fonts/Gibson-Regular.otf");
}

@font-face {
  font-family: "Gibson-SemiBold";
  src: url("../assets/fonts/Gibson-SemiBold.otf");
}

@font-face {
  font-family: "Gibson-SemiBoldItalic";
  src: url("../assets/fonts/Gibson-SemiBoldItalic.otf");
}

@font-face {
  font-family: "Gibson-Thin";
  src: url("../assets/fonts/Gibson-Thin.otf");
}

@font-face {
  font-family: "Gibson-ThinItalic";
  src: url("../assets/fonts/Gibson-ThinItalic.otf");
}
/* svg {
  color: var(--rosaClaro);
  stroke: var(--rosaClaro);
} */
:root {
  --red: #dc3545;
  --white: #ffffff;
  --black: #000000;
  --blue: #1946bb;
  --light-blue: #4b73f0;
  --guinda: #4a001f;
  /* --guinda: #8d949e; */
  --rosaClaro: #ffc3d0;
  /* --rosaClaro: #dadde1; */
  --rosaAlert: #ffe6ec;
  /* --rosaAlert: #ccd0d5; */
  --morado: #6a0f49;
  /* --morado: #8d949e; */
  --orage: #ffc107;
  --warning: #f9e8c6;
  --light-orange: #ffaf17;
  --green: #198754;
  --primary-text: #050505;
  --secondary-text: #65676b;
  --input-gray: #f0f2f5;
  --gray-00: #f5f6f7;
  --gray-05: #f2f3f5;
  --gray-10: #ebedf0;
  --gray-20: #dadde1;
  --gray-25: #ccd0d5;
  --gray-30: #bec3c9;
  --gray-45: #8d949e;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1c1e21;
  --lightblue-alpha: rgba(75, 115, 240, 0.1);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --overlay-alpha-80: rgba(244, 244, 244, 0.8);
  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
}

* {
  margin: 0px;
  padding: 0px;
  /* border:1px solid red; */
}

a,
a:link,
a:hover,
a:visited,
a:focus-visible {
  text-decoration: none;
  color: unset;
  border: none;
  outline: none;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.container-gif {
  position: fixed;
  width: 100%;
  background-color: rgba(190, 195, 201, 0.7);
  height: 100%;
  z-index: 111;
  top: 0;
  left: 0;
}

.logoGif {
  width: 300px;
}

body {
  font-family: "Gibson-Regular";
  letter-spacing: 1.1px;
  font-weight: 500;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.containerTodo {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  /* Propiedades de estilo para el header */
  width: 100%;
}

.mainContainer {
  padding-bottom: 1%;
  flex: 1;
  /* Propiedades de estilo para el mainContainer */
}

.footer {
  /* Propiedades de estilo para el footer */
  width: 100%;
}

/*------------------------------ Menu----------------------- */
.boderBottom--Rosa {
  border-bottom: 5px solid var(--rosaClaro);
}

.nav-link {
  color: var(---gray-20) !important;
}

.logo-header {
  width: 200px;
}

.itemNav-header {
  padding: 5px;
  cursor: pointer;
  color: var(--guinda);
}

.itemNav-header:hover {
  background-color: var(--rosaClaro);
  color: var(--guinda);
  border-radius: 5px;
  font-weight: 700;
}

.itemDropDown-header {
  padding: 5px !important;
  color: var(--guinda);
}

.itemDropDown-header:hover {
  cursor: pointer;
  color: var(--guinda);
  font-weight: 700;
  background-color: var(--rosaClaro);
}

.dropdown {
  padding: 5px;
  border-radius: 5px;
  color: var(--guinda);
}

.dropdown:hover {
  color: var(--guinda);
  font-weight: 700;
  background-color: var(--rosaClaro);
}

.menuIzquierdo {
  background-color: #fff;
  background-color: var(--white);
  border-right: 1px solid #dadde1;
  border-right: 1px solid var(--gray-20);
  left: 0;
  overflow: auto;
  padding: 10px 15px;
  position: fixed;
  top: 0;
  width: 310px;
  z-index: 100;
}

.menuDerecho {
  background-color: #fff;
  background-color: var(--white);
  border-right: 1px solid #dadde1;
  border-right: 1px solid var(--gray-20);
  right: 0;
  overflow: auto;
  padding: 10px 15px;
  position: fixed;
  top: 0;
  width: 225px;
  z-index: 100;
}

.menu {
  padding: 10px 15px;
  background-color: var(--white);
  border-right: 1px solid var(--gray-20);
  display: flex;
  flex-direction: column;
}

.menu_item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 10px;
  color: var(--gray-45);
  font-family: "Montserrat";
  font-weight: 700;
}

.menu_item svg {
  font-size: 22px;
}

.menu_item:hover .badge {
  background-color: var(--white);
  color: var(--blue);
}

.menu_item:hover {
  background-color: var(--guinda);
  color: white;
  stroke: white;
}

.menu_item:hover svg path {
  stroke: white;
}

.menu_item--disable {
  color: var(--gray-20);
  cursor: not-allowed;
}

.menu_item--disable:hover {
  background-color: var(--gray-45);
  color: white;
  stroke: white;
}

.menu_item .toggle {
  font-size: 15px;
}

.menu_item .toggle:hover {
  border-radius: 50%;
  background-color: white;
  color: var(--blue);
}

.brand {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 40px;
  padding-left: 10px;
  font-family: "Montserrat" !important;
  font-size: 1.32rem;
}

.brand img {
  width: 260px;
}

.border-bottom--guinda {
  border-bottom: 2px solid var(--guinda);
}

.border-bottom--rosa {
  border-bottom: 2px solid var(--rosaClaro);
}

/*------------------------------ Footer ----------------------- */
.footerEstilos {
  position: relative;
  margin: auto;
  bottom: 0;
  width: 100%;
}
.border-top--rosa {
  border-top: 5px solid var(--rosaClaro);
}

.logoGris {
  max-width: 240px;
  height: 120px;
}

.logoSello {
  max-width: 130px;
}

.redes {
  width: 400px;
}

.backgrounPlaca {
  background-image: url(../assets/img/placa.png);
  background-size: cover;
  height: 40px;
}

/*------------------------------ Background ----------------------- */
.bg-white {
  background-color: var(--white);
}

.bg-guinda {
  background-color: var(--guinda);
}

.bg-armarillo {
  background-color: var(--warning);
}

.bg-morado {
  background-color: var(--morado);
}

.bg-rosaClaro {
  background-color: var(--rosaClaro);
}

.bg-rosaAlert {
  background-color: var(--rosaAlert);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-orange {
  background-color: var(--orage);
}

.bg-light-orange {
  background-color: var(--light-orange);
}

.bg-green {
  background-color: var(--green);
}

.bg-gray-00 {
  background-color: var(--gray-00);
}

.bg-gray-05 {
  background-color: var(--gray-05);
}

.bg-gray-10 {
  background-color: var(--gray-10);
}

.bg-gray-20 {
  background-color: var(--gray-20);
}

.bg-gray-25 {
  background-color: var(--gray-25);
}

.bg-gray-30 {
  background-color: var(--gray-30);
}

.bg-gray-45 {
  background-color: var(--gray-45);
}

.bg-gray-70 {
  background-color: var(--gray-70);
}

.bg-gray-80 {
  background-color: var(--gray-80);
}

.bg-gray-90 {
  background-color: var(--gray-90);
}

.bg-gray-90 {
  background-color: var(--gray-100);
}

/*------------------------------ Textos Basado Tamaño ----------------------- */

.text-big {
  font-size: 1.62rem;
}

.text-medium {
  font-size: 1.18rem !important;
}

.text {
  font-size: 1rem !important;
}

.text-small {
  font-size: 10px;
}

/*------------------------------ Texto primario y secundario ----------------------- */
.text-primary {
  color: var(--primary-text) !important;
}

.text-secondary {
  color: var(--secondary-text) !important;
}

.text-tertiary {
  color: var(--black) !important;
}

/*------------------------------ Textos de tipo negritas  ----------------------- */
.text-bold {
  font-weight: 700;
}

.text-bolder {
  font-weight: 900;
}

/*------------------------------ Imagen de Perfil ----------------------- */

/*------------------------------ textos de colores ----------------------- */

.text-guinda {
  color: var(--guinda);
}

.text-morado {
  color: var(--morado);
}

.text-gray {
  color: var(--gray-45);
}

.text-light-blue {
  color: var(--light-blue);
}

.text-orange {
  color: var(--orage);
}

.text-light-orange {
  color: var(--light-orange);
}

.text-red {
  color: var(--red);
}

.text-blue {
  color: var(--blue);
}

.text-white {
  color: var(--white);
}

/*------------------------------ textos de colores ----------------------- */
.GibsonBold {
  font-family: "Gibson-Bold";
}

.GibsonBoldItalic {
  font-family: "Gibson-BoldItalic";
}

.GibsonBook {
  font-family: "Gibson-Book";
}

.GibsonBookItalic {
  font-family: "Gibson-BookItalic";
}

.GibsonHeavy {
  font-family: "Gibson-Heavy";
}

.GibsonHeavyItalic {
  font-family: "Gibson-HeavyItalic";
}

.GibsonItalic {
  font-family: "Gibson-Italic";
}

.GibsonLight {
  font-family: "Gibson-Light";
}

.GibsonLightItalic {
  font-family: "Gibson-LightItalic";
}

.GibsonMedium {
  font-family: "Gibson-Medium";
}

.GibsonMediumItalic {
  font-family: "Gibson-MediumItalic";
}

.GibsonRegular {
  font-family: "Gibson-Regular";
}

.GibsonSemiBold {
  font-family: "Gibson-SemiBold";
}

.GibsonSemiBoldItalic {
  font-family: "Gibson-SemiBoldItalic";
}

.GibsonThin {
  font-family: "Gibson-Thin";
}

.GibsonThinItalic {
  font-family: "Gibson-ThinItalic";
}

/*------------------------------ Botones ----------------------- */
.cta {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 25px;
  border-radius: 10px;
  /* font-family: Montserrat; */
  font-weight: 700;
  border: none;
  max-width: 380px;
}

.cta:hover {
  transform: scale(1.005);
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
}

.cta--guinda {
  background-color: var(--guinda);
  color: white;
}

.cta--blue {
  background-color: var(--blue);
  color: white;
}

.cta--orange {
  background-color: var(--orage);
  color: white;
}

.cta--green {
  background-color: var(--green);
  color: white;
}

.cta--red {
  background-color: var(--red);
  color: white;
}

.cta--purple {
  background-color: var(--morado);
  color: white;
}

.cta--disable {
  background-color: var(--gray-45);
  color: white;
  cursor: not-allowed;
}

.cta--short {
  width: 100px;
}

.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  border: 1px solid palevioletred;
  border-radius: 30px;
  padding: 10px 20px;
  background-color: var(--rosaAlert);
  color: var(--guinda);
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10);
}

.pill-little {
  height: 35px;
  width: auto;
  margin-left: 10% !important;
}

.pill-green {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  border: 1px solid var(--green);
  border-radius: 30px;
  padding: 10px 20px;
  background-color: white;
  color: var(--green);
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10);
}

.pill-red {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  border: 1px solid var(--red);
  border-radius: 30px;
  padding: 10px 20px;
  background-color: white;
  color: var(--red);
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10);
}

.pill-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  border: 1px solid var(--light-blue);
  border-radius: 30px;
  padding: 10px 20px;
  background-color: var(--white);
  color: var(--light-blue);
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10);
}

.pill-green:hover {
  /* border: 1px solid var(--); */
  background-color: var(--green);
  color: white !important;
}

.pill-red:hover {
  /* border: 1px solid var(--); */
  background-color: var(--red);
  color: white !important;
}

.pill-blue:hover {
  /* border: 1px solid var(--); */
  background-color: var(--light-blue);
  color: white !important;
}

.pill:hover {
  /* border: 1px solid var(--); */
  background-color: var(--guinda);
  color: white !important;
}

.pill--active {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white !important;
}

.pill--disabled {
  border: 1px solid var(---gray-30);
  background-color: var(--gray-20);
  color: var(--gray-30) !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.pill--disabled:hover {
  border: 1px solid var(---gray-30);
  background-color: var(--gray-20);
  color: var(--gray-30) !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

/*------------------------------ Form Input ----------------------- */

.form-input {
  width: 92%;
  max-width: 380px;
  height: 45px;
  margin: 10px 0px;
  border: none;
  background-color: var(--input-gray);
  color: #65676b;
  outline: none;
  border-radius: 40px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input-big {
  width: 100%;
  max-width: 800px;
  height: 70px;
  margin: 10px 0px;
  border: none;
  background-color: var(--input-gray);
  color: #65676b;
  outline: none;
  border-radius: 40px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input-small {
  width: 92%;
  max-width: 380px;
  height: 30px;
  margin: 10px 0px;
  border: none;
  background-color: var(--input-gray);
  color: #65676b;
  outline: none;
  border-radius: 40px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input-little {
  width: 92%;
  max-width: 150px;
  height: 30px;
  margin: 10px 0px;
  border: none;
  background-color: var(--input-gray);
  color: #65676b;
  outline: none;
  border-radius: 40px;
  padding: 0px 15px;
  font-size: 14px;
}

.btn-outline-secondary {
  width: 8%;
  max-width: 380px;
  height: 45px;
  margin: 10px 0px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: solid;
  border-width: 2px;
  border-color: #4a001f;
  background-color: var(--input-gray);
  color: #4a001f;
  outline: none;
  border-radius: 40px;
  padding: 0px 1px;
  font-size: 14px;
}

.form-input--full {
  max-width: inherit !important;
}

.form-input--short {
  width: 100px;
}

.form-input--disabled {
  border: 1px solid var(---gray-20);
  background-color: var(--gray-20);
  color: var(--gray-40) !important;
  cursor: not-allowed;
}

.form-input--disabled:hover {
  border: 1px solid var(---gray-20);
  background-color: var(--gray-20);
  color: var(--gray-40) !important;
  cursor: not-allowed;
}

.form-input--icon {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.form-input_icon {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  background-color: var(--input-gray);
  padding-right: 15px;
}

/*------------------------------ Dotloader de Colores ----------------------- */

.dottedloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.dottedloader .dottedloader_dot:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.dottedloader .dottedloader_dot:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.dottedloader_dot {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  border-radius: 50%;
  -webkit-animation: dotjump;
  animation: dotjump;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.dottedloader--small .dottedloader_dot {
  width: 8px !important;
  height: 8px !important;
  margin-left: 1px;
  margin-right: 1px;
}

@-webkit-keyframes dotjump {
  0% {
    top: 0px;
  }

  50% {
    top: -8px;
  }

  100% {
    top: 0px;
  }
}

@keyframes dotjump {
  0% {
    top: 0px;
  }

  50% {
    top: -8px;
  }

  100% {
    top: 0px;
  }
}

/*------------------------------ Tables ----------------------- */
.table {
  --bs-table-striped-bg: var(--rosaAlert);
  color: var(--guinda);
}

th:first-of-type {
  border-top-left-radius: 40px;
}

th:last-of-type {
  border-top-right-radius: 40px;
}

tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 40px;
}

tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 40px;
}

.page-item {
  padding: 3%;
  size: 30px;
  width: auto;
  color: var(--guinda);
}

.page-link {
  padding: 3%;
  size: 30px;
  color: var(--guinda);
}

/*------------------------------ Icon ----------------------- */
.icon_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  cursor: pointer;
  color: #ccc;
  height: 50px;
  flex: none;
}

.icon_btn:hover {
  background-color: #f5f5f5;
  color: var(--morado);
}

.icon_btn--big svg {
  font-size: 30px !important;
}

.icon_btn--small {
  width: 30px !important;
  height: 30px !important;
}

.icon_btn--rounded {
  border-radius: 50% !important;
}

.icon_btn--active {
  color: #1a47bc;
  background-color: var(--gray-00);
  border-bottom: none;
}

.icon_btn--disable {
  color: #ccc;
  cursor: not-allowed;
}

.icon_btn--disable:hover {
  background-color: inherit !important;
  color: #ccc !important;
}

.icon_btn:hover > .icon_btn_help,
.icon:hover > .icon_help {
  opacity: 1;
}

.icon_btn_help {
  text-align: center;
  white-space: nowrap;
  position: relative;
  opacity: 0;
  background-color: red;
  position: absolute;
  top: 50px;
  font-size: 10px;
  background-color: var(--blue);
  color: white;
  border-radius: 5px;
  line-height: 1.2;
  padding: 4px 8px;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  z-index: 1;
}

.icon_btn_help::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--blue);
}

.icon_help {
  pointer-events: none;
  text-align: center;
  opacity: 0;
  background-color: red;
  position: absolute;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: -84px;
  width: 200px;
  font-size: 10px;
  background-color: var(--blue);
  color: white;
  border-radius: 5px;
  line-height: 1.2;
  padding: 6px 8px;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  z-index: 11;
  cursor: default;
}

.icon_help::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--blue);
}

/*------------------------------ Card ----------------------- */

.card {
  position: relative !important;
  width: 100%;
  border: 1px solid var(--guinda);
  border-radius: 0px !important;
  padding: 10px;
  box-shadow: 2.5px 2.5px 5px 0px var(--black-alpha-05);
  background-color: var(--rosaAlert);
}

.card.card--rounded {
  border-radius: 30px !important;
}

.card.card--rounded-green {
  border-radius: 30px !important;
  border: 1px solid var(--green);
  background-color: var(--green);
}

.card.card--rounded-gray {
  border-radius: 40px !important;
  border: 1px solid var(--gray-70);
  background-color: var(--gray-25);
}

.bottomCard {
  margin-top: auto;
}

/*------------------------------ PopUp ----------------------- */
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 640px;
  left: 0px;
  top: 0px;
  background-color: var(--black-alpha-30);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.8px);
  z-index: 99;
  cursor: auto;
}

.overlay--transparent {
  background-color: transparent !important;
  backdrop-filter: none !important;
}

.overlay--mid {
  z-index: 10 !important;
}

.wizard {
  overflow: auto;
  /* min-width: 1070px; */
  width: 85%;
  max-width: 95%;
  max-height: 95%;
  /* max-height: calc(100% - -15em); */
  background-color: var(--white);
  /* overflow: hidden; */
  padding: 15px 30px 15px 15px;
  box-shadow: 3px 3px 12px 0px var(--shadow-2);
  border-radius: 10px;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto !important;
  height: auto;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
}
.wizard-funcionario {
  top: 30% !important;
}

/*------------------------------ Notificaciones ----------------------- */
.notificacion {
  position: relative;
  bottom: 10px;
  margin-bottom: 5px;
  z-index: 1024;
  background-color: var(--white);
  min-width: 360px;
  max-width: 480px;
  min-height: 80px;
  box-shadow: -20px 30px 50px 20px var(--black-alpha-05);
  padding: 10px 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.notificacion--success {
  border-left: 3px solid var(--green);
}

.notificacion--error {
  border-left: 3px solid red;
}

.notificacion--warning {
  border-left: 3px solid var(--light-orange);
}

.notificacion--option {
  border-left: 3px solid var(--morado);
}

.notificacion-animate {
  opacity: 1 !important;
  right: 10px !important;
}

.notification-animate-enter {
  opacity: 0;
  right: 0;
}

.notification-animate-enter-active {
  opacity: 1;
  right: 10px;
  transition: all 200ms;
}

.notification-animate-exit {
  opacity: 1;
  right: 10px;
}

.notification-animate-exit-active {
  opacity: 0;
  right: 0px;
  transition: all 200ms;
}

.notificacions {
  position: fixed;
  z-index: 1024;
  padding: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  bottom: 0px;
  right: 0px;
}

.mt-100 {
  margin-top: 100px;
}
