    /* Extra Small (XS) */
    @media (max-width: 575.98px) {
        /* Estilos para dispositivos con ancho de pantalla hasta 575.98px */
        
        .footer-container{
            padding: 0.1rem !important;
        }
        .inside-footer-container{
            padding: 0.2rem !important;
        }
        .contenedor-textos{
            font-size: 1rem !important;
        }
        .footer-icono{
            margin: -10px !important;
            margin: 0 !important;
        }
        .icon_btn{
            font-size: 32px !important;
        }
        .texto-enlace{
            font-size: 0.5rem !important;
        }
        .logos-container{
            flex-direction: column !important;
            justify-content: center !important;
            align-items: flex-start !important;
        }
        .logoGris {
            width: 70% !important; 
            height: 30% !important;
        }
        
        .logoSello {
            margin-top: 0.25rem;
            width: 30% !important; 
            height: 30% !important;
            margin-left: 0 !important;
        }
        .contenedor-hashtag{
            font-size: 0.3rem !important;
        }
        .oficina-logos-telefono{
            width: 5px !important;
            height: 5px !important;
        }
        .oficina-textos-telefonos{
            font-size: 0.7rem !important;
        }
        .texto-info-funcionario{
            margin-left: 15px !important;
            text-align: center !important;
        }
        .imagen-funcionario{
            width: 60px !important;
            height: 60px !important;
        }
    }
    
    /* Small (SM) */
    @media (min-width: 576px) and (max-width: 767.98px) {
    /* Estilos para dispositivos con ancho de pantalla desde 576px hasta 767.98px */
    .icon_btn{
        font-size: 32px !important;
    }
    .texto-enlace{
        font-size: 0.6rem !important;
    }
    .oficina-logos-telefono{
        width: 15px !important;
        height: 15px !important;
    }
    .oficina-textos-telefonos{
        font-size: 0.7rem !important;
    }
    .logoSello {
        margin-top: 0.25rem;
        width: 25% !important; 
        height: 25% !important;
        margin-left: 0 !important;
    }
    .texto-info-funcionario{
        margin-left: 15px !important;
        text-align: center !important;
    }
    .imagen-funcionario{
        width: 70px !important;
        height: 70px !important;
    }

    }
  
  /* Medium (MD) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    /* Estilos para dispositivos con ancho de pantalla desde 768px hasta 991.98px */
    .logoGris {
        width: 75% !important; 
        height: 30% !important;
    }
      
    .logoSello {
        margin-top: 0.25rem;
        width: 35% !important; 
        height: 30% !important;
    }
    .icon_btn{
        font-size: 32px !important;
    }
    .texto-enlace{
        font-size: 0.6rem !important;
    }
  }
  
  /* Large (LG) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    /* Estilos para dispositivos con ancho de pantalla desde 992px hasta 1199.98px */
    .logoSello {
        margin-top: 0.25rem;
        width: 35% !important; 
        height: 30% !important;
        margin-left: 0 !important;
    }
  }
  
  /* Extra Large (XL) */
  @media (min-width: 1200px) {
    /* Estilos para dispositivos con ancho de pantalla de 1200px en adelante */
    .logoSello {
        margin-top: 0.25rem;
        width: 35% !important; 
        height: 30% !important;
    }
  }
  